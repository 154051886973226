import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NgbDateStruct,
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom, of, Subject, Subscription } from 'rxjs';
import { GettingStartedFormGroup } from './getting-started.form';
import * as GettingStartedFormConfig from './getting-started.form.config';
import { StateMessageModalComponent } from './state-message-modal/state-message-modal.component';
import { StateMessageService } from '@core/state-message/state-message-service';
import {
  AddressConfiguration,
  EditAddressControls,
  EditAddressLabels,
  getDefaultAddressControls
} from '@elevate/address';
import { GettingStartedValidator } from './getting-started.validator';
import { StateEligibilityService } from './state-eligibility/state-eligibility.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import {
  GoogleAnalytics,
  GoogleTagManagerVariables
} from '@core/google-analytics/googleanalytics.service';
import { StateNotServicedModalComponent } from './state-not-serviced-modal/state-not-serviced-modal.component';
import { Environment } from '@environment/environment';
import {
  CmsGettingStarted,
  CmsMailingAddress,
  CmsOfferDetails,
  DefaultModal
} from './getting-started.content';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { AffectCreditScoreModalComponent } from './affect-credit-score-modal/affect-credit-score-modal.component';
import { RadioButton } from '@elevate/ui-components';
import { FormControl } from '@angular/forms';
import {
  ApplicationFlow,
  ApplicationForm,
  ShortAppOffer
} from '@application/application';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import {
  ConfigurationService,
  EnrollmentCodeStatesResponse,
  PlatformConfigFeatures
} from '@core/configuration/configuration.service';
import { ConsentHelper } from '@application/consents/consent.helper';
import { CurrencyPipe } from '@angular/common';
import moment from 'moment';
import { GettingStarted } from './getting-started';
import {
  StartOption,
  StartOptionsResponse
} from '@application/application.api';
import { RedirectToLegacyModalComponent } from './redirect-to-legacy-modal/redirect-to-legacy-modal.component';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { ResumeModalComponent } from './resume-modal/resume-modal.component';
import { GoToSigninModalComponent } from './goto-signin-modal/goto-signin-modal.component';
import { SafeHtml } from '@angular/platform-browser';

export declare interface GettingStartedPageBase {
  recaptchaIsHidden: boolean;
  showDebugHud: string;
  submit(): void;
}

@Component({ template: '' })
export class GettingStartedBaseComponent implements OnDestroy, OnInit {
  protected onDestroyed = new Subject<boolean>();
  public formConfig = GettingStartedFormConfig;
  public creditScoreImpactDisclosure: any;
  public ngbModalSubscription: Subscription;
  public lastSelectedState: string;

  public isDisabled: any;
  public model: NgbDateStruct;
  public date: { year: number; month: number };
  public isStateEligible = true;

  public addressConfig: AddressConfiguration;
  public addressLabels: EditAddressLabels;

  public consentToCommunicationContainerName = 'consentToCommunicationConsents';
  public addressControls: EditAddressControls;
  public mailingAddressControls: EditAddressControls;
  public cmsPageContent: CmsGettingStarted; // content object of "Prequalification: Getting Started Common" Content type
  public pageSubTitleContent: SafeHtml;
  public pageConsentSectionTitle: string;
  public pageConsentSectionContent: SafeHtml;
  public consentsSection: ConsentSectionItem[];
  public consentToCommunication: ConsentSectionItem[] = [];
  public cmsMailingAddressContent: CmsMailingAddress;
  public cmsOfferDetails: CmsOfferDetails;
  public isMailingSectionEnabled: boolean;
  public isMailingAddressSameChecked: boolean;
  public alternatePhoneEnabled: boolean;
  public radioButtonItem: RadioButton;
  public shortAppOffer: ShortAppOffer;
  public enrollmentCodeTooltip: string;
  public enrollmentCodeConfig: EnrollmentCodeStatesResponse;
  public affectedCreditSectionEnabled: boolean;

  constructor(
    public form: GettingStartedFormGroup,
    public modalService: NgbModal,
    public stateMessageService: StateMessageService,
    public validators: GettingStartedValidator,
    public stateEligibilityService: StateEligibilityService,
    public googleAnalytics: GoogleAnalytics,
    public appInsightsService: AppInsightsService,
    public environment: Environment,
    public route: ActivatedRoute,
    public todayCardHandleService: TodayCardHandleService,
    public configurationService: ConfigurationService,
    public currencyPipe: CurrencyPipe,
    public loadingService: LoadingModalService,
    public window: Window
  ) {
    this.cmsPageContent = this.route.snapshot.data.cmsContent.prequalificationGettingStarted.gettingStartedCommon;
    this.affectedCreditSectionEnabled = this.route.snapshot.data.cmsContent.prequalificationGettingStarted.affectingCreditScoreEnabled;
    this.addressLabels = {
      addressLine1: this.cmsPageContent.addressLabels.labels.addressLine1,
      addressLine2: this.cmsPageContent.addressLabels.labels.addressLine2,
      city: this.cmsPageContent.addressLabels.labels.city,
      state: this.cmsPageContent.addressLabels.labels.state,
      postalCode: this.cmsPageContent.addressLabels.labels.postalCode
    };
    this.addressConfig = {
      siteKey: this.environment.smartyStreets.siteKey,
      url: {
        lookup: this.environment.smartyStreets.url.suggest,
        lookupCheck: this.environment.smartyStreets.url.lookup
      },
      stateBlackListed: [],
      threeColumnDisplay: true
    };
  }

  public async ngOnInit(): Promise<void> {
    this.addressControls = getDefaultAddressControls(null, false, true);

    Object.keys(this.addressControls).forEach((key: string) => {
      this.form.addControl(key, this.addressControls[key]);
    });

    this.enrollmentCodeTooltip = this.cmsPageContent.messages.enrollmentCodeTooltip;

    this.consentsSection = ConsentHelper.converToArray(
      this.route.snapshot.data.cmsContent.prequalificationGettingStarted
        .consentsSection
    );
    if (this.cmsPageContent.disclosures.consentToCommunication != null) {
      this.consentToCommunication = [
        {
          consent: this.cmsPageContent.disclosures.consentToCommunication
        }
      ];
    }
    this.cmsMailingAddressContent = this.route.snapshot.data.cmsContent.prequalificationMailingAddress;
    this.cmsOfferDetails = this.route.snapshot.data.cmsContent.offerDetails;
    if (this.cmsOfferDetails) {
      this.cmsOfferDetails.primeRateDisclosure = await this.todayCardHandleService.replaceMargin(
        this.cmsOfferDetails.primeRateDisclosure
      );
    }

    if (this.cmsMailingAddressContent) {
      this.form.addControl('mailingAddressCheckBox', new FormControl(true));
      this.isMailingSectionEnabled = true;
      this.isMailingAddressSameChecked = true;
      this.alternatePhoneEnabled = this.cmsMailingAddressContent.isenabled;
      if (this.alternatePhoneEnabled) {
        this.form.addAlternatePhoneControls();
        this.setAlternatePhoneRadioGroup();
      }
    }

    try {
      this.enrollmentCodeConfig = await lastValueFrom(
        this.configurationService.getConfigFeature<
          EnrollmentCodeStatesResponse
        >(PlatformConfigFeatures.DMCodeFieldRequired)
      );
    } catch (error) {
      console.error('ERROR: ENROLLMENT CODE CONFIGURATION NOT AVAILABLE');
    }
  }

  public ngOnDestroy(): void {
    this.onDestroyed.next(true);
    if (this.ngbModalSubscription) {
      this.ngbModalSubscription.unsubscribe();
    }
  }

  public async onStateUpdate(stateCode: string): Promise<void> {
    this.stateEligibilityService.stateCode = stateCode;
    lastValueFrom(
      this.stateEligibilityService.getStateResource(stateCode)
    ).then(res => {
      this.stateEligibilityService.setStateResourceAge(res?.ageRequirement);
      this.stateEligibilityService.setProductConfig(res);
      this.formConfig = {
        ...this.formConfig,
        dobConfig: {
          ...this.formConfig.dobConfig,
          maxDate: this.form.getCalendarMaxDate(res?.ageRequirement)
        }
      };
      this.isStateEligible = res && res.isLoanServiced;
      if (this.isStateEligible) {
        this.checkForStateMessage(stateCode);
      } else {
        this.isStateNotEligible();
      }
      this.changeEnrolmentCodeField(stateCode);
    });
  }

  public openAffectCreditScore(): void {
    const modalRef = this.modalService.open(AffectCreditScoreModalComponent, {
      ariaLabelledBy: 'Disclosure Modal'
    });
    modalRef.componentInstance.htmlContent = this.cmsPageContent.modals.affectCreditScoreModal;
    this.ngbModalSubscription = modalRef.shown.subscribe(() => {
      document
        .getElementsByTagName('ngb-modal-window')[0]
        .setAttribute('aria-label', 'View Credit Score Modal');
    });
  }

  public checkForStateMessage(stateAbbreviation: string): void {
    const message = this.stateMessageService.getStateMessage(
      stateAbbreviation,
      this.route.snapshot.data.cmsContent.stateMessages
    );
    const stateHasChanged = this.lastSelectedState !== stateAbbreviation;

    if (message && stateHasChanged) {
      const stateModalRef = this.modalService.open(StateMessageModalComponent, {
        windowClass: 'state-message-modal',
        centered: true
      });
      this.cmsPageContent.modals.stateMessageModal.content = message.message;
      stateModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.stateMessageModal;
    }

    this.lastSelectedState = stateAbbreviation;
  }

  public isStateNotEligible(): boolean {
    if (!this.isStateEligible) {
      const stateNotModalRef = this.modalService.open(
        StateNotServicedModalComponent,
        {
          windowClass: 'state-not-serviced-modal'
        }
      );
      stateNotModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.stateNotMessageModal;
    }
    return !this.isStateEligible;
  }

  private setAlternatePhoneRadioGroup(): void {
    this.formConfig.alternatePhoneRadioGroupConfig.buttons = [];
    this.cmsMailingAddressContent.altPhoneRadioGroup.forEach(element => {
      this.radioButtonItem = {
        id: 'alternatePhone' + element.displayLabel,
        label: element.displayLabel,
        name: 'alternatePhoneButtonGroup',
        value: element.value,
        attributes: {
          'data-nid-target':
            'alternatePhone' + element.displayLabel + 'RadioButton'
        },
        disabled: 'false'
      };
      this.formConfig.alternatePhoneRadioGroupConfig.buttons.push(
        this.radioButtonItem
      );
    });
  }

  private async changeEnrolmentCodeField(stateCode: string): Promise<void> {
    if (this.enrollmentCodeConfig) {
      const enrollmentCodeIsRequired = this.enrollmentCodeConfig?.states.find(
        state => state.name === stateCode
      );

      if (!this.enrollmentCodeConfig.enabled) {
        return;
      }

      if (enrollmentCodeIsRequired) {
        this.form.setEnrollmentCodeField(
          this.cmsPageContent.validationMessages,
          true
        );

        this.enrollmentCodeTooltip = this.cmsPageContent.messages.requiredEnrollmentCodeTooltip;
      }

      if (!enrollmentCodeIsRequired) {
        this.form.setEnrollmentCodeField(
          this.cmsPageContent.validationMessages,
          false
        );

        this.enrollmentCodeTooltip = this.cmsPageContent.messages.enrollmentCodeTooltip;
      }
    }
  }

  protected updategoogleAnalyticsVariables(userFlow: ApplicationFlow): void {
    const variables: GoogleTagManagerVariables = {
      state: this.form.value.state,
      application_start_date: moment.utc().format(),
      userType: userFlow
    };

    if (this.form.value.code) {
      variables.enrollment_code = this.form.value.code;
    }
    this.googleAnalytics.setGoogleTagManagerVariables(variables);
  }

  protected createGettingStartedData(form: ApplicationForm): GettingStarted {
    if (!form) {
      return null;
    }

    const dateOfBirth = moment(
      form?.applicant?.identity?.dateOfBirth,
      'YYYY-MM-DD'
    ).format('MM/DD/YYYY');
    const address = form?.applicant?.residences?.find(
      residence => residence.type === 'Current'
    ).address;

    const email = form?.applicant?.emails?.find(
      emailInfo => emailInfo.type === 'Personal'
    );
    const phone = form?.applicant?.phones?.find(
      phoneInfo => phoneInfo.key === '1'
    );

    let addressMailing;
    let alternatePhone;

    if (this.isMailingSectionEnabled) {
      addressMailing = form?.applicant?.residences?.find(
        residence => residence.type === 'Mailing'
      )?.address;

      alternatePhone = form?.applicant?.phones?.find(
        phoneInfo => phoneInfo.key === '2'
      );

      if (addressMailing) {
        Object.keys(address).forEach(k => {
          if (address[k] !== addressMailing[k]) {
            this.isMailingAddressSameChecked = false;
          }
        });
      }
    }
    const ssn = form?.applicant?.identity?.socialSecurityNumber;

    const gettingStarted: GettingStarted = {
      firstname: form?.applicant?.identity?.firstName,
      lastname: form?.applicant?.identity?.lastName,
      suffix: form?.applicant?.identity?.suffix,
      dob: dateOfBirth,
      addressLine1: address?.line1,
      addressLine2: address?.line2?.trim(),
      city: address?.city,
      state: address?.stateCode,
      postalCode: address?.zipCode,
      email: email?.address,
      mobile: phone?.number,
      alternatePhoneNumber: alternatePhone?.number,
      alternatePhoneType: alternatePhone?.type,
      code: form.enrollmentCode?.trim()
    };

    if (!ssn?.includes('X')) {
      gettingStarted.ssn = ssn;
    }

    return gettingStarted;
  }

  protected createApplicationRequest(
    formData: GettingStarted,
    continuePath: string,
    existingDataDiff?
  ): ApplicationForm {
    const dob = moment(formData.dob, 'MMDDYYYY');

    const application: ApplicationForm = {
      continuePath: continuePath,
      applicant: {
        identity: {
          socialSecurityNumber: formData.ssn,
          firstName: formData.firstname,
          lastName: formData.lastname,
          dateOfBirth: dob.format('YYYY-MM-DD')
        },
        emails: [
          {
            key: '1',
            address: formData.email,
            type: 'Personal'
          }
        ],
        phones: [
          {
            key: '1',
            number: formData.mobile,
            type: 'Mobile'
          }
        ],
        residences: [
          {
            key: '1',
            type: 'Current',
            address: {
              zipCode: formData.postalCode,
              stateCode: formData.state,
              line1: formData.addressLine1,
              city: formData.city
            }
          }
        ]
      }
    };

    if (this.shortAppOffer) {
      // cleaning empty shortAppOffer properties
      Object.entries(this.shortAppOffer).map((value: any) => {
        if (!value[1]) {
          delete this.shortAppOffer[value[0]];
        }
      });

      application.shortAppOffer = {
        ...this.shortAppOffer
      };
    }

    if (
      formData.addressLine1 ||
      existingDataDiff?.some(item => ['suite'].includes(item.path[0]))
    ) {
      application.applicant.residences[0].address.line2 =
        formData.addressLine2 || ' ';
    }
    if (formData.suffix) {
      application.applicant.identity.suffix = formData.suffix;
    }
    if (
      formData.code ||
      existingDataDiff?.some(item => ['code'].includes(item.path[0]))
    ) {
      application.enrollmentCode = formData.code || ' ';
    }
    if (this.isMailingSectionEnabled) {
      if (formData?.alternatePhoneNumber) {
        application.applicant.phones.push({
          key: '2',
          number: formData.alternatePhoneNumber,
          type: formData.alternatePhoneType
        });
      }

      application.applicant.residences.push({
        key: '2',
        type: 'Mailing',
        address: {
          zipCode: formData?.mailingAddress_postalCode || formData.postalCode,
          stateCode: formData?.mailingAddress_state || formData.state,
          line1: formData?.mailingAddress_addressLine1 || formData.addressLine1,
          line2:
            formData?.mailingAddress_addressLine2 ||
            formData.addressLine2 ||
            ' ',
          city: formData?.mailingAddress_city || formData.city
        }
      });
    }

    return application;
  }

  protected handleRedirectToLegacyStartOption(
    existingData: GettingStarted,
    startOptionsResponse: StartOptionsResponse,
    redirectContent: any,
    partnerDetails?: any
  ) {
    let appData: any = {
      firstName: existingData?.firstname || '',
      lastName: existingData?.lastname || '',
      zipCode: existingData?.postalCode || '',
      state: existingData?.state || '',
      email: existingData?.email || '',
      mobilePhoneNumber: existingData?.mobile || '',
      directMailCode: existingData?.code || '',
      abTesting: startOptionsResponse.abTesting || []
    };

    if (partnerDetails) {
      appData = { ...partnerDetails, state: existingData?.state };
    }
    if (startOptionsResponse.abTesting) {
      appData.abTesting = startOptionsResponse.abTesting;
      this.googleAnalytics.setGoogleTagManagerVariables(
        {
          abTesting: appData.abTesting
        },
        true
      );
    }
    const encodedAppData = encodeURIComponent(btoa(JSON.stringify(appData)));
    this.loadingService.close();
    
    const redirectToLegacyModalRef = this.modalService.open(
      RedirectToLegacyModalComponent,
      {
        backdrop: 'static',
        keyboard: false
      }
    );
    redirectToLegacyModalRef.componentInstance.htmlContent =
      redirectContent.modal;
    redirectToLegacyModalRef.result.then(result => {
      this.window.location.href = `${startOptionsResponse.redirectUrl}?redirect=${encodedAppData}`;
    });
    setTimeout(() => {
      redirectToLegacyModalRef.close();
    }, redirectContent.duration);
  }

  protected openResumeModal(
    startOptionsResponse: StartOptionsResponse
  ): NgbModalRef {
    const resumeModalRef = this.modalService.open(ResumeModalComponent, {
      backdrop: 'static',
      windowClass: 'resume-modal'
    });
    resumeModalRef.componentInstance.data = {
      ...startOptionsResponse,
      firstName: this.form.value.firstname
    };
    resumeModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.resumeModal;
    return resumeModalRef;
  }

  protected openGoToSignInModal(
    startOptionsResponse: StartOptionsResponse,
    isPartnerPrequal: boolean = false,
    partnerContent?: DefaultModal
  ): NgbModalRef {
    const goToModalRef = this.modalService.open(GoToSigninModalComponent, {
      backdrop: 'static',
      windowClass: 'goto-signin-modal'
    });
    goToModalRef.componentInstance.redirectionReason = startOptionsResponse.startOptions.toString();
    goToModalRef.componentInstance.userEmail = this.form.get('email').value;
    if (isPartnerPrequal) {
      goToModalRef.componentInstance.htmlContent = partnerContent;
    } else {
      goToModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.goToSigninModal;
    }
    return goToModalRef;
  }

  public updateKeepHidden(value: boolean) {
    this.formConfig.ssnConfig.keepHidden = value;
  }
}
