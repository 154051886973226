import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationMessagesError, Rule } from '@elevate/forms';

export const bankAccountNumberValidatorName = 'bankAccountNumber';

export function bankAccountNumberValidator(
  errorText: string,
  rules: Rule[] = []
): ValidatorFn {
  return function(control: AbstractControl): ValidationErrors {
    if (control.value != null) {
      if (isBankAccountNumberValid(control.value)) {
        return null;
      } else {
        return new ValidationMessagesError(
          bankAccountNumberValidatorName,
          rules,
          errorText
        );
      }
    }
  };
}

export function isBankAccountNumberValid(accountNumber: string) {
  const regexForGreaterThan15 = /(^(?![45])[0-9]{16,17})(?!.)/;

  const regexForLessThan15 = /(^([0-9]){1,15})(?!.)/;

  if (
    accountNumber.length > 15 &&
    regexForGreaterThan15.test(accountNumber) &&
    accountNumber.length <= 17
  ) {
    return true;
  } else if (
    accountNumber.length <= 15 &&
    regexForLessThan15.test(accountNumber)
  ) {
    return true;
  } else {
    return false;
  }
}
