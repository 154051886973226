import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  SecurityContext,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
  AbTestingData,
  ApplicationData,
  ApplicationFlow,
  ApplicationForm,
  Disclosure,
  ShortAppOffer
} from '@application/application';
import {
  ApplicationApi,
  StartOption,
  StartOptionsRequest,
  StartOptionsResponse
} from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { CookieService, CookieEnums } from '@core/cookie/cookie-service';
import { CookieAttributes } from 'js-cookie';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { StateMessageService } from '@core/state-message/state-message-service';
import { AutocompleteOption } from '@elevate/ui-components';
import { Environment } from '@environment/environment';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import * as deepDiff from 'deep-diff';
import moment from 'moment';
import {
  lastValueFrom,
  Subscription,
  distinctUntilChanged,
  pairwise,
  take
} from 'rxjs';
import { GettingStartedDebugService } from './debug-hud/debug-hud.service';
import { GettingStarted } from './getting-started';
import { GettingStartedFormGroup } from './getting-started.form';
import * as gettingStartedFormConfig from './getting-started.form.config';
import { GettingStartedValidator } from './getting-started.validator';
import { GoToSigninModalComponent } from './goto-signin-modal/goto-signin-modal.component';
import { NoOptionModalComponent } from './no-option-modal/no-option-modal.component';
import { ReCaptchaService } from './reCaptcha/recaptcha.service';
import { RedirectToLegacyModalComponent } from './redirect-to-legacy-modal/redirect-to-legacy-modal.component';
import { ResumeModalComponent } from './resume-modal/resume-modal.component';
import { StateMessageModalComponent } from './state-message-modal/state-message-modal.component';
import {
  ECCustomDateParserFormatter,
  ECCustomDateAdapter
} from '@core/datepicker/EDCustomDatepickerAdapter.service';
import {
  GoogleAnalytics,
  GoogleTagData,
  GoogleTagManagerVariables
} from '@core/google-analytics/googleanalytics.service';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { GettingStartedBaseComponent } from './getting-started-base.component';
import { StateEligibilityService } from './state-eligibility/state-eligibility.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';

import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { ValidationMessagesError } from '@elevate/forms';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import { CurrencyPipe } from '@angular/common';
import { ConfigurationService } from '@core/configuration/configuration.service';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import {
  LogRocketService,
  AMSAppSessionCorrelator
} from '@core/logrocket/logrocket.service';
import { TemporaLockService } from '@core/temp-lock/temp-lock.service';
import { TemporaryLockModalComponent } from './temporary-lock-modal/temporary-lock-modal.component';

@Component({
  selector: 'app-apply-home',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  providers: [
    GettingStartedFormGroup,
    GettingStartedDebugService,
    GettingStartedValidator,
    {
      provide: NgbDateAdapter,
      useClass: ECCustomDateAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: ECCustomDateParserFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class GettingStartedComponent extends GettingStartedBaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  @ViewChild('consentToCommunicationComponent')
  private consentToCommunicationComponent: ConsentsComponent;
  public showDebugHud = 'gettingStarted';
  public pageSubTitle: string;
  public pageContent: SafeHtml;
  public routeSubscription: Subscription;
  public formConfig = gettingStartedFormConfig;
  public resumeCookie: string;
  public isPartnerPrePop = false;
  public recaptchaToken: string;
  private nextPage: string;
  private shortAppOfferRequiredFields = [
    'firstname',
    'lastname',
    'email',
    'state',
    'code',
    'creditLimit',
    'annualFee',
    'purchaseAPR',
    'transferAPR'
  ];
  private resumeCanceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  public gcid: string;
  public gcidCookieValue: string;
  public audId: string;

  public subId: string;
  public formChangesSubscription: Subscription;

  public abTestingNode?: AbTestingData[];
  public abTestingFromRedirect?: AbTestingData[];
  public stateFromRedirect?: string;

  constructor(
    public form: GettingStartedFormGroup,
    public debugHud: GettingStartedDebugService,
    public modalService: NgbModal,
    public stateMessageService: StateMessageService,
    private reCaptchaService: ReCaptchaService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private applicationApi: ApplicationApi,
    public environment: Environment,
    private sessionStorageService: SessionStorageService,
    public loadingService: LoadingModalService,
    private cookieService: CookieService,
    public googleAnalytics: GoogleAnalytics,
    private userAppStarted: UserInactivityStatusService,
    private neuroIdService: NeuroIdService,
    public validators: GettingStartedValidator,
    public stateEligibilityService: StateEligibilityService,
    public appInsightsService: AppInsightsService,
    public pageHeaderService: CmsPageContentService,
    public applicationFlowService: ApplicationFlowService,
    public currencyPipe: CurrencyPipe,
    public todayCardHandleService: TodayCardHandleService,
    @Inject('window') public window: Window,
    public configurationService: ConfigurationService,
    private activatedRoute: ActivatedRoute,
    private logrocketService: LogRocketService,
    private tempLockService: TemporaLockService
  ) {
    super(
      form,
      modalService,
      stateMessageService,
      validators,
      stateEligibilityService,
      googleAnalytics,
      appInsightsService,
      environment,
      route,
      todayCardHandleService,
      configurationService,
      currencyPipe,
      loadingService,
      window
    );
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.resumeCookie = this.cookieService.get(CookieEnums.ResumeByAppId);
    this.cmsPageContent = this.route.snapshot.data.cmsContent.prequalificationGettingStarted.gettingStartedCommon;

    if (
      this.cmsPageContent.modals.affectCreditScoreModal
        ?.additionalMessageToApplicant
    ) {
      this.cmsPageContent.modals.affectCreditScoreModal.additionalMessageToApplicant = this.cmsPageContent.modals.affectCreditScoreModal.additionalMessageToApplicant.replaceAll(
        '{buttonText}',
        this.cmsPageContent.buttons.continue
      );
    }
    this.form.build(this.cmsPageContent.validationMessages);
    const dateRangeMessage = this.cmsPageContent.validationMessages.dateOfBirth
      .otherErrorMessage;
    this.formConfig.emailConfig.disabled = 'false';
    this.formConfig.ssnConfig.disabled = 'false';
    this.formConfig.dobConfig.errorDateRange = dateRangeMessage
      ? dateRangeMessage
      : this.formConfig.dobConfig.errorDateRange;

    this.pageSubTitle = this.cmsPageContent.subHeader;
    this.pageContent = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.cmsPageContent.content
    );

    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      this.gcid = params['gcid'];
      this.audId = params['aud_id'];
      this.subId = params['subid'];
      this.gcidCookieValue = this.cookieService.getCookieValue(
        CookieEnums.gcid
      );

      this.gcid = this.gcid ? this.gcid : this.gcidCookieValue;
      const cookieAttributes: CookieAttributes = {
        domain: this.environment.setCookieConfig?.gcid.domain,
        expires: this.environment.setCookieConfig?.gcid.expiryInDays
      };

      if (this.gcid && this.gcid != this.gcidCookieValue) {
        this.cookieService.set(CookieEnums.gcid, this.gcid, cookieAttributes);
      }

      this.sessionStorageService.setItem(
        SessionStorageKeys.applicationGCID,
        this.gcid
      );

      this.googleAnalytics.setOneTimeGlobalData();
    });

    if (this.environment.brand === 'Today') {
      this.populateShortAppData();
      this.fillVariablesInCmsOfferDetails();
    }

    if (this.resumeCookie) {
      this.callStartOptionById(this.resumeCookie);
    }

    this.populateData();
    this.pageHeaderService.updatePageTitle(this.cmsPageContent.header);
  }

  public async ngAfterViewInit(): Promise<void> {
    this.formChangesSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        pairwise(), // gets a pair of old and new value
        take(1)
      )
      .subscribe(([oldValue, newValue]) => {
        this.googleAnalytics.logFormInteractionEventsForChangedValues(
          oldValue,
          newValue,
          false
        );
      });

    setTimeout(() => {
      this.googleAnalytics.formViewEvent();
      const logRocketData: AMSAppSessionCorrelator = {
        appInsightsSessionId: this.appInsightsService.getGoogleTagAppInsightsTelemetry()
          .appInsightsSesssionId,
        ga4SessionId: this.cookieService.getSessionId()
      };
      this.logrocketService.trackAppLogRocketCustomEvent(logRocketData);
      const gtagData: GoogleTagManagerVariables = {
        aud_id: this.audId,
        gcid: this.gcid,
        sub_id: this.subId,
        full_referral_url: document.referrer,
        userType: ApplicationFlow.OrganicPrequal,
        visitorid: this.appInsightsService.getAppInsightsAccountId(),
        neuroId: this.neuroIdService.neuroIdSessionId,
        logrocket_id: this.cookieService.getSessionId()
      };

      this.googleAnalytics.setGoogleTagManagerVariables(gtagData, true);
    }, 3000);
  }
  public async onFocusOut(event: any): Promise<void> {
    this.googleAnalytics.formInteractionEvent({
      field_id: event.target.id,
      step_name: window.location.pathname
    });
  }

  public onAddressSuggestionError(event: any) {
    this.appInsightsService.trackException(event);
  }

  public async submit(): Promise<void> {
    if (!this.applicationFlowService.getApplicationFlows())
      await this.applicationFlowService.setApplicationFlows();

    this.nextPage = this.applicationFlowService.getContinuePath(
      null,
      ApplicationFlow.OrganicPrequal
    );

    if (super.isStateNotEligible()) {
      return;
    }
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    const existingData = this.createGettingStartedData(
      this.applicationDataService.getApplication()?.form
    );

    if (!existingData) {
      this.loadingService.open();
      await this.callStartOptions();
    } else {
      this.loadingService.open();
      const formValue = this.form.value;
      const diff = deepDiff(existingData, formValue);

      if (!diff) {
        this.router.navigate([this.nextPage]);
      } else if (diff.some(item => ['ssn', 'email'].includes(item.path[0]))) {
        await this.callStartOptions(existingData);
      } else {
        const formData = this.createApplicationRequest(
          formValue,
          this.nextPage,
          diff
        );
        if (diff.some(item => ['state'].includes(item.path[0]))) {
          formData.reEvaluateABTesting = true;
        }
        this.appendDisclosuresToFormData(formData);
        await this.appendApplicationData(formData);
      }
    }
  }

  private setRedirection(
    existingData: GettingStarted,
    evaluateABTest: any,
    redirectContent: any
  ) {
    this.loadingService.close();
    const appData: any = {
      firstName: existingData?.firstname || '',
      lastName: existingData?.lastname || '',
      zipCode: existingData?.postalCode || '',
      state: existingData?.state || '',
      email: existingData?.email || '',
      mobilePhoneNumber: existingData?.mobile || '',
      directMailCode: existingData?.code || '',
      abTesting: evaluateABTest.body.value.abTesting || []
    };

    const encodedAppData = encodeURIComponent(btoa(JSON.stringify(appData)));
    const redirectToLegacyModalRef = this.modalService.open(
      RedirectToLegacyModalComponent,
      {
        backdrop: 'static',
        keyboard: false
      }
    );
    redirectToLegacyModalRef.componentInstance.htmlContent =
      redirectContent.modal;
    redirectToLegacyModalRef.result.then(result => {
      this.window.location.href = `${evaluateABTest.body.value.redirectUrl}?redirect=${encodedAppData}`;
    });
    setTimeout(() => {
      redirectToLegacyModalRef.close();
    }, redirectContent.duration);
  }

  /*Added this method in formData to reevaluate the ABTesting*/
  private appendReEvaluateABTestingToFormData(
    formData: ApplicationForm,
    flag: boolean
  ): void {
    formData.reEvaluateABTesting = flag;
  }
  private appendDisclosuresToFormData(formData: ApplicationForm): void {
    if (formData.disclosures == null) {
      formData.disclosures = [];
    }
    if (this.consentToCommunicationComponent?.disclosures?.length == 0) {
      var electronicDisclosure: Disclosure = {
        key: 'electronicCommunicationAgreement',
        consentGiven: true,
        isIndependentDocument: false
      };
      formData.disclosures.push(electronicDisclosure);
    }
    formData.disclosures = formData.disclosures.concat(
      this.consentToCommunicationComponent?.disclosures
    );
    if (this.consentsComponent) {
      formData.disclosures = formData.disclosures.concat(
        this.consentsComponent?.disclosures
      );
    }
    formData.applicant.electronicCommunicationAgreement = formData.disclosures?.find(
      x => x?.key == 'electronicCommunicationAgreement'
    )?.consentGiven;
  }

  public setCityAndState(option: AutocompleteOption): void {
    const city = this.formConfig.cityMap[option.id].city;
    const state = this.formConfig.cityMap[option.id].state;
    this.form.patchValue({ city });
    this.form.patchValue({ state });
  }

  private async callStartOptions(existingData?: GettingStarted): Promise<void> {
    let startOptionResponse: StartOptionsResponse;

    const startOptionsRequest: StartOptionsRequest = {
      brand: this.environment.brand,
      applicationFlow: 'OrganicPrequal',
      socialSecurityNumber: this.form.get('ssn').value,
      email: this.form.get('email').value,
      stateCode: this.form.get('state').value,
      dateOfBirth: this.form.get('dob').value
    };

    if (
      this.abTestingFromRedirect != null &&
      this.stateFromRedirect == this.form.get('state').value
    ) {
      startOptionsRequest.abTesting = this.abTestingFromRedirect;
    }

    if (this.debugHud.mockStartOption === 'Call Api') {
      startOptionResponse = await lastValueFrom(
        this.applicationApi.startOptions(startOptionsRequest)
      );
    } else {
      startOptionResponse = this.debugHud.getMockApiResponse();
      const authToken = this.sessionStorageService.getItem(
        SessionStorageKeys.authorizationToken
      );
      if (authToken) {
        const data: ApplicationData = await lastValueFrom(
          this.applicationApi.get()
        );
        startOptionResponse.applicationId = data.id;
      }
    }

    switch (startOptionResponse.startOptions) {
      case StartOption.RedirectToLegacy:
        if (existingData) {
          const diff = deepDiff(existingData, this.form.value);
          if (diff.some(item => ['state'].includes(item.path[0]))) {
            const formData = this.createApplicationRequest(
              this.form.value,
              this.nextPage,
              diff
            );
            formData.reEvaluateABTesting = true;
            await this.appendApplicationData(formData);
          }
        }
        const redirectContent = this.route.snapshot.data.cmsContent
          .prequalificationGettingStarted.redirectToLegacy;
        if (redirectContent) {
          this.handleRedirectToLegacyStartOption(
            existingData,
            startOptionResponse,
            redirectContent
          );
        }
        return;
      case StartOption.NoOptions:
        const lockStatus = this.tempLockService.checkLockStatus();

        if (lockStatus) {
          this.loadingService.close();

          const tempLockModalContent = this.route.snapshot.data.cmsContent
            .prequalificationGettingStarted.temporaryLockModal;
          const temporaryLockModalRef = this.modalService.open(
            TemporaryLockModalComponent,
            {
              backdrop: 'static',
              keyboard: false
            }
          );
          temporaryLockModalRef.componentInstance.htmlContent = tempLockModalContent;
          return;
        }

        this.loadingService.close();
        const noOptionModalRef = this.modalService.open(NoOptionModalComponent);
        noOptionModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.noOptionModal;
        return;
      case StartOption.RedirectToBau:
      case StartOption.RedirectFormerRefinanceSignIn:
        this.loadingService.close();
        this.openGoToSignInModal(startOptionResponse);
        return;
      case StartOption.StartNewOnly:
        if (this.environment.brand == 'Rise') {
          this.abTestingNode = [
            {
              testName: 'AbTestingRedirectToBlueprint',
              segmentName: 'RedirectToBlueprintPlatform'
            } as AbTestingData
          ];
        }
        this.createApplication();
        return;
      case StartOption.ResumeOrStartNew:
      case StartOption.ResumeOnly: {
        this.loadingService.close();
        const resumeModalRef = this.openResumeModal(startOptionResponse);
        resumeModalRef.result.then(data => {
          if (data && !data.resumeApplication) {
            this.createApplication();
          }
        });
        return;
      }
    }
  }

  private async createApplication(): Promise<void> {
    if (
      this.sessionStorageService.getItem(
        SessionStorageKeys.authorizationToken
      ) !== null
    ) {
      const formData = this.createApplicationRequest(
        this.form.value,
        this.nextPage
      );
      await this.appendApplicationData(formData);
      return;
    }

    this.loadingService.close();
    this.recaptchaToken = this.debugHud.disableReCaptcha
      ? 'skip-recaptcha'
      : await lastValueFrom(this.reCaptchaService.execute());
    this.loadingService.open();

    const formData = this.createApplicationRequest(
      this.form.value,
      this.nextPage
    );
    this.appendDisclosuresToFormData(formData);
    let isSuccessfull = false;

    let abTesting =
      this.abTestingFromRedirect == null
        ? this.abTestingNode
        : this.abTestingFromRedirect;

    await lastValueFrom(
      this.applicationApi.create({
        brand: this.environment.brand,
        applicationFlow: 'OrganicPrequal',
        form: formData,
        recaptchaToken: this.recaptchaToken,
        abTesting: abTesting
      })
    ).then(
      httpResponse => {
        this.sessionStorageService.setItem(
          SessionStorageKeys.authorizationToken,
          httpResponse.token
        );
        isSuccessfull = true;
      },
      httpError => {
        if (
          httpError.status === 422 &&
          httpError?.error['message'] === 'EnrollmentCode'
        ) {
          this.form
            .get('code')
            .setErrors(
              new ValidationMessagesError(
                'enrollmentCode',
                null,
                this.cmsPageContent.validationMessages.enrollmentCode.otherErrorMessage
              )
            );
          this.loadingService.close();
        } else if (httpError.error.message.includes('is not Serviced')) {
          this.loadingService.close();
          this.isStateEligible = false;
          this.isStateNotEligible();
        }
      }
    );

    if (!isSuccessfull) {
      return;
    }

    this.updategoogleAnalyticsVariables(ApplicationFlow.OrganicPrequal);
    this.applicationDataService.replaceApplicationForm({
      form: formData
    });

    if (this.resumeCookie) {
      this.cookieService.remove(CookieEnums.ResumeByAppId);
    }

    this.userAppStarted.setUserApplicationStatus(true);
    this.neuroIdService.setVariable('funnel', ApplicationFlow.OrganicPrequal);
    this.router.navigate([this.nextPage]);
  }

  private async appendApplicationData(
    formData: ApplicationForm
  ): Promise<void> {
    this.applicationDataService.replaceApplicationForm({ form: formData });
    await lastValueFrom(this.applicationApi.append(formData)).then(
      evaluateABTest => {
        if (
          evaluateABTest != null &&
          evaluateABTest.body?.value?.startOptions ==
            StartOption.RedirectToLegacy
        ) {
          // update google tag with ab testing
          this.googleAnalytics.setGoogleTagManagerVariables(
            {
              abTesting: evaluateABTest.body.value.abTesting || []
            },
            true
          );
          const redirectContent = this.route.snapshot.data.cmsContent
            .prequalificationGettingStarted.redirectToLegacy;
          if (redirectContent) {
            this.setRedirection(
              this.form.value,
              evaluateABTest,
              redirectContent
            );
          }
        } else {
          this.router.navigate([this.nextPage]);
          this.loadingService.close();
        }
      },
      (error: any) => {
        if (error.error.message.includes('is not Serviced')) {
          this.loadingService.close();
          this.isStateEligible = false;
          this.isStateNotEligible();
        }
      }
    );
  }

  private populateData(): void {
    const applicationForm = this.applicationDataService.getApplication()?.form;
    if (applicationForm) {
      setTimeout(() => {
        this.form.patchValue(this.createGettingStartedData(applicationForm));
      });
    } else {
      this.userAppStarted.setUserApplicationStatus(false);
      if (this.route.snapshot.queryParams['sf']) {
        let queryData: any = JSON.parse(
          atob(decodeURIComponent(this.route.snapshot.queryParams['sf']))
        );
        const sfDataObject = Object.fromEntries(
          Object.entries(queryData).map(([k, v]) => [k.toLowerCase(), v])
        );
        this.form.patchValue(sfDataObject);
      } else if (this.route.snapshot.queryParams['id']) {
        this.callStartOptionById(this.route.snapshot.queryParams['id']);
      } else if (this.route?.snapshot?.queryParams['redirect']) {
        const redirectDataObject: any = JSON.parse(
          atob(decodeURIComponent(this.route.snapshot.queryParams['redirect']))
        ) as GettingStarted;
        this.googleAnalytics.redirectionFromLegacy(redirectDataObject?.state);
        if (redirectDataObject?.abTesting) {
          this.abTestingFromRedirect = redirectDataObject?.abTesting;
          this.stateFromRedirect = redirectDataObject?.state;
        }
        this.form.patchValue(redirectDataObject);
      }
    }
    if (this.form.value.state) {
      this.onStateUpdate(this.form.value.state);
    }
  }

  public checkForStateMessage(stateAbbreviation: string): void {
    const message = this.stateMessageService.getStateMessage(
      stateAbbreviation,
      this.route.snapshot.data.cmsContent.stateMessages.stateMessage
    );
    const stateHasChanged = this.lastSelectedState !== stateAbbreviation;

    if (message && stateHasChanged) {
      const stateModalRef = this.modalService.open(StateMessageModalComponent, {
        windowClass: 'state-message-modal',
        centered: true
      });
      this.cmsPageContent.modals.stateMessageModal.content = message.message;
      stateModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.stateMessageModal;
    }

    this.lastSelectedState = stateAbbreviation;
  }

  private async callStartOptionById(cookieAppID): Promise<void> {
    this.loadingService.open();

    try {
      const startOptionByIdResponse = await lastValueFrom(
        this.applicationApi.startOptionsByID({
          applicationId: cookieAppID,
          brand: this.environment.brand
        })
      );
      if (
        startOptionByIdResponse.startOptions === StartOption.ResumeOnly ||
        startOptionByIdResponse.startOptions === StartOption.ResumeOrStartNew
      ) {
        this.loadingService.close();

        const resumeModalRef = this.modalService.open(ResumeModalComponent, {
          backdrop: 'static',
          windowClass: 'resume-modal'
        });
        resumeModalRef.componentInstance.data = {
          ...startOptionByIdResponse,
          applicationId: cookieAppID,
          firstName: this.form.value.firstname
        };
        resumeModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.resumeModal;
        resumeModalRef.result.then(
          data => {
            if (data && !data.resumeApplication) {
              this.cookieService.remove(CookieEnums.ResumeByAppId);
            }
          },
          reason => {
            this.resumeCanceled.emit(true);
          }
        );
        return;
      } else {
        this.cookieService.remove(CookieEnums.ResumeByAppId);
        this.loadingService.close();
        this.resumeCanceled.emit(true);
      }
    } catch {
      this.cookieService.remove(CookieEnums.ResumeByAppId);
      this.modalService.dismissAll();
    }
  }

  public get recaptchaIsHidden(): boolean {
    return this.debugHud.disableReCaptcha;
  }

  public debugHudSubmit(): void {
    this.debugHud.simulateData();
    setTimeout(() => {
      this.consentsComponent?.debugHudSubmitAllConsents();
    });
  }

  public onMailingAddressSameChange(event: any): void {
    this.isMailingAddressSameChecked = event.target.checked;
    var data: GoogleTagData = {
      step_name: event.target.id
    };
    this.googleAnalytics.formInteractionEvent(data);
  }

  private populateShortAppData(): void {
    const applicationForm = this.applicationDataService.getApplication()?.form;
    if (applicationForm?.shortAppOffer) {
      this.shortAppOffer = applicationForm.shortAppOffer;
    } else if (this.route.snapshot.queryParams['offer']) {
      this.shortAppOffer = JSON.parse(
        atob(decodeURIComponent(this.route.snapshot.queryParams['offer']))
      ) as ShortAppOffer;
      if (this.shortAppOffer.code?.trim()) {
        this.formConfig.codeConfig.disabled = 'true';
      }
    }

    if (
      !this.shortAppOffer ||
      this.shortAppOfferRequiredFields.some(
        field => this.shortAppOffer[field] == null
      )
    ) {
      this.redirectToStartPage();
    }
    this.form.patchValue(this.shortAppOffer);
  }

  private redirectToStartPage(): void {
    this.window.location.href = this.environment.sessionExpirationRedirectUrl;
  }

  private fillVariablesInCmsOfferDetails(): void {
    if (this.cmsOfferDetails?.section != null) {
      this.cmsOfferDetails.section = this.convertToArray(
        this.cmsOfferDetails.section
      );
      this.cmsOfferDetails.section.forEach(section => {
        if (section.sectionText != null) {
          section.sectionText = this.convertToArray(section.sectionText);
          section.sectionText.forEach(sectionLine => {
            const formattedAnnualFeePerMonth = this.currencyPipe.transform(
              this.shortAppOffer?.annualFee / 12,
              'USD',
              'symbol',
              '1.0-2'
            );
            sectionLine.line = sectionLine.line.replace(
              '{annualFeePerMonth}',
              formattedAnnualFeePerMonth
            );
          });
        }
      });
    }
  }

  private convertToArray(entity): any {
    if (entity == null) {
      return [];
    }

    if (Array.isArray(entity)) {
      return entity;
    }

    return [entity];
  }
  public async ngOnDestroy(): Promise<void> {
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }
}
