import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { ApplicationData, BankDataProviders } from '@application/application';
import {
  UnderwritingDecisionEnum,
  UnderwritingSteps
} from '@application/underwriting/underwriting.model';
import {
  UnderwritingApiService,
  UnderwritingResponseModel
} from '@application/underwriting/underwriting-api.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { lastValueFrom } from 'rxjs';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import {
  ConfigurationService,
  Decision,
  DecisionFlowResponse,
  PlatformConfigFeatures
} from '@core/configuration/configuration.service';

export interface CmsCheckingOptions {
  messages: CmsWaitingMessage[];
}

export interface CmsWaitingMessage {
  message: string;
  secondsDelay: number;
}

@Component({
  selector: 'app-checking-options',
  templateUrl: './checking-options.component.html',
  styleUrls: ['./checking-options.component.scss']
})
export class CheckingOptionsComponent implements OnInit {
  private cmsContent: CmsCheckingOptions;
  private currentMessage = 0;
  public displayMessage: string;
  private decisionFlowResponse: DecisionFlowResponse;
  public abSkipConnectBankTestEnabled: boolean;
  private abSkipConnectBankTestOption: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private applicationApiService: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private underwritingService: UnderwritingApiService,
    private neuroIdService: NeuroIdService,
    private cmsPageContentService: CmsPageContentService,
    public googleAnalytics: GoogleAnalytics,
    private configurationService: ConfigurationService
  ) {
    this.cmsContent = this.activatedRoute.snapshot.data.cmsContent
      .checkingOptions as CmsCheckingOptions;
    this.cmsPageContentService.updatePageTitle('');
    this.messageCycle();
  }

  public async ngOnInit(): Promise<void> {
    const application: ApplicationData = this.applicationDataService.getApplication();

    this.abSkipConnectBankTestOption = this.applicationDataService
      .getApplication()
      .abTesting?.find(
        abTest => abTest.testName === 'AbTestingSkipConnectBankPage'
      );

    this.abSkipConnectBankTestEnabled =
      this.abSkipConnectBankTestOption?.segmentName ===
      'SkipConnectBankPagePlatform'
        ? true
        : false;

    this.neuroIdService.setCheckpoint('prequal_decisioning_checkpoint');

    this.decisionFlowResponse = await lastValueFrom(
      this.configurationService.getConfigFeature<DecisionFlowResponse>(
        PlatformConfigFeatures.DecisionFlow
      )
    );

    if (
      !this.underwritingService.isPrequalUnderwritingProcessing &&
      !this.underwritingService.hasPrequalUnderwritingCompleted
    ) {
      this.underwritingService.submitApplicationPrequalUnderwriting(
        application.id
      );
    }

    const response = await lastValueFrom(
      this.underwritingService.prequalUnderwritingDecisionSubject
    );

    this.applicationDataService.mergeArray(
      this.convertToApplicationData(response)
    );

    const nextPath = this.determineDecisionPage(
      response.underwrite.disposition
    );

    await lastValueFrom(
      this.applicationApiService.append({ continuePath: nextPath })
    );

    this.router.navigate([nextPath]);
  }

  private messageCycle(): void {
    this.showMessage(this.currentMessage);
    if (this.currentMessage < this.cmsContent.messages.length - 1) {
      setTimeout(
        this.messageCycle.bind(this),
        this.cmsContent.messages[this.currentMessage].secondsDelay * 1000
      );
      this.currentMessage++;
    }
  }

  private showMessage(num: number): void {
    this.displayMessage = this.cmsContent.messages[num].message;
  }

  private convertToApplicationData(
    underwritingPrequalData: UnderwritingResponseModel
  ): ApplicationData {
    const appData: ApplicationData = {
      mostRecentDecision: underwritingPrequalData.underwrite.disposition.type,
      underwriting: {
        steps: [
          {
            name: UnderwritingSteps.Prequal,
            status: underwritingPrequalData.underwrite.status,
            disposition: underwritingPrequalData.underwrite.disposition
          }
        ],
        decisions: []
      }
    };

    if (underwritingPrequalData.offer) {
      appData.offer = underwritingPrequalData.offer;
    }

    return appData;
  }

  private determineDecisionPage(disposition: any): string {
    let targetUrl: any;
    switch (disposition.type) {
      case UnderwritingDecisionEnum.Offer:
        targetUrl = 'pre-qualification-approval';
        break;
      case UnderwritingDecisionEnum.Decline:
        targetUrl = this.getDeclineDecisionPage(
          'pre-qualification-decline',
          disposition.noaa?.code
        );
        break;
      case UnderwritingDecisionEnum.Continue:
        targetUrl = this.abSkipConnectBankTestEnabled
          ? 'bank-information'
          : 'connect-bank';
        break;
      case UnderwritingDecisionEnum.NoOffer:
        targetUrl = this.abSkipConnectBankTestEnabled
          ? 'bank-information'
          : 'connect-bank';
        break;
      case UnderwritingDecisionEnum.CounterOffer:
        targetUrl = 'pre-qualification-approval';
        break;
      default:
        targetUrl = this.abSkipConnectBankTestEnabled
          ? 'bank-information'
          : 'connect-bank';
        break;
    }
    return targetUrl;
  }

  public getDeclineDecisionPage(
    defaultDeclineDecisionPage: string,
    code?: string
  ): string {
    if (this.decisionFlowResponse && code) {
      let decision: Decision = this.decisionFlowResponse.decision.find(
        x => x.code === code
      );
      if (decision != undefined && decision.declineDecisionPage != null) {
        return decision.declineDecisionPage;
      }
    }

    return defaultDeclineDecisionPage;
  }
}
