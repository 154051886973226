<ecl-base-modal
  class="modal-body verification-body"
  [modalConfig]="modalData"
  (closeModal)="
    googleAnalytics.clickModalEvent({
      action: 'Close',
      modal_name: this.htmlContent.title
    })
  "
  (openModal)="
    googleAnalytics.viewModalEvent({
      modal_name: this.htmlContent.title
    })
  "
>
  <div *ngIf="page === 'SendCode'">
    <div *ngIf="isOtpVerification">
      <h1 id="sendCodeTitle" ngbAutofocus class="modal-title">
        {{ content.sendCodeHeader }}
      </h1>
      <div class="dialog-content">
        <p id="sendCodeContent" class="sub-text">
          {{ content.sendCodeContent }}
        </p>
        <form class="col-sm-8" [formGroup]="contactTypeForm">
          <div class="form-input">
            <ecl-radio
              [formControlConfig]="formConfig.contactTypeConfig"
              formControlName="sendCodeMethod"
              (change)="logFormInteractionForRadioButton($event)"
            >
            </ecl-radio>
          </div>
          <span
            id="ssnVerificationLink"
            class="ecl-font-link-light-bg"
            (click)="useSSNVerification($event)"
          >
            {{ content.telephoneLink }}
          </span>
          <div class="btn-container">
            <button
              class="ecl-button-primary"
              id="sendCodeBtn"
              (click)="sendVerificationCode()"
              googleAnalyticsClick="PartnerVerification-SendCode"
            >
              {{ content.buttons.sendCode }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div *ngIf="page === 'VerifyCode'">
    <h1 id="verifyCodeTitle" class="modal-title ecl-top-l">
      {{ content.verifyCodeHeader }}
    </h1>
    <div class="dialog-content">
      <p id="verifyCodeContent" class="sub-text">
        {{ content.verifyCodeContent }}
      </p>
      <form class="col-sm-8" [formGroup]="verificationForm">
        <div class="form-input">
          <ecl-number
            id="verificationCodeInput"
            autocomplete="off"
            formControlName="verificationCode"
            [formControlConfig]="formConfig.verificationCodeConfig"
          >
            {{ content.verificationCode }}
          </ecl-number>
        </div>
      </form>
    </div>
    <div class="ecl-button-hgroup ecl-wrap-reverse btn-container">
      <button
        id="verifyCodeButton"
        class="ecl-button-primary"
        (click)="verificationSubmit()"
        googleAnalyticsClick="PartnerVerification-Submit"
      >
        {{ content.buttons.submit }}
      </button>
      <button
        id="goBackButton"
        class="ecl-button-secondary"
        (click)="goBack()"
        googleAnalyticsClick="PartnerVerification-Back"
      >
        {{ content.buttons.back }}
      </button>
    </div>
  </div>

  <div *ngIf="page === 'VerifySSN' || !isOtpVerification">
    <h1 id="verifySSNTitle" class="ecl-top-l">
      {{ content.verifySsnHeader }}
    </h1>
    <form class="col-sm-8" [formGroup]="ssnVerificationForm">
      <div class="content-sections">
        <p id="verifySSNContent" class="sub-text">
          {{ content.verifySsnContent }}
        </p>
        <div class="form-input">
          <ecl-text-input
            id="last4SSNnput"
            formControlName="last4SSN"
            autocomplete="off"
            [formControlConfig]="formConfig.ssnVerificationMethodConfig"
            googleAnalyticsFocusOut="PartnerVerification-LastFourSsn"
          >
            {{ content.lastSsnInput }}
          </ecl-text-input>
        </div>
        <span
          *ngIf="isOtpVerification"
          id="codeVerificationLink"
          class="ecl-font-link-light-bg"
          (click)="useContactVerification($event)"
        >
          {{ content.contactVerificationLink }}
        </span>
      </div>
    </form>
    <div class="btn-container">
      <button
        id="verifySSnCodeButton"
        class="ecl-button-primary"
        (click)="ssnVerificationSubmit()"
        googleAnalyticsClick="PartnerVerification-VerifySsn-Submit"
      >
        {{ content.buttons.submit }}
      </button>
    </div>
  </div>
</ecl-base-modal>
